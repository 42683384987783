// import axios from 'axios';
// import React, { useState } from 'react';

// const API_KEY = "AIzaSyDPeru1xPfpg0432_rCHm0qjSdVwMFDsfw"; // Your YouTube API key

// function Search() {
//   const [results, setResults] = useState([]);

//   const searchYouTube = async (e) => {
//     e.preventDefault();
//     const query = document.getElementById("searchInput").value.trim();
//     if (!query) {
//       alert("Please enter a search query");
//       return;
//     }

//     const maxResults = 10;
//     const order = document.getElementById("order") ? document.getElementById("order").value : "relevance";
//     const type = "channel";

//     try {
//       const response = await axios.get(
//         `https://www.googleapis.com/youtube/v3/search?part=snippet&q=${query}&type=${type}&order=${order}&maxResults=${maxResults}&key=${API_KEY}`
//       );
//       setResults(response.data.items);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       alert("Error fetching data from YouTube. Please try again later.");
//     }
//   };

//   return (
//     <div className="content">
//       <div className="container mt-4">
//         <h1>Search Results</h1>
//         <div className="form-group">
//           <form className="form-inline ml-auto" onSubmit={searchYouTube}>
//             <input className="form-control mr-sm-2" type="search" id="searchInput" placeholder="Search" aria-label="Search" />
//             <button className="btn btn-outline-light my-2 my-sm-0" style={{ backgroundColor: '#b6ddf7' }} type="submit">Search</button>
//           </form>
//           <label htmlFor="order" style={{ margin: '10px 10px' }}>Order By:</label>
//           <select className="form-control" id="order">
//             <option value="relevance">Channel</option>
//             <option value="viewCount">Video</option>
//           </select>
//         </div>
//         <div id="results" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', maxWidth: '100%', boxSizing: 'border-box' }}>
//           {results.map(result => (
//             <div key={result.id.channelId} className="result-item" style={{ paddingTop: '40px', textAlign: 'center', border: '0.5px solid rgb(187, 183, 183)', flex: '1 1 calc(25% - 10px)', maxWidth: 'calc(25% - 10px)', margin: '5px', boxSizing: 'border-box' }}>
//               <a href={`/profile?channelId=${result.id.channelId}`}>
//                 <img src={result.snippet.thumbnails.medium.url} alt={result.snippet.title} style={{ maxWidth: '100px', marginRight: '10px', borderRadius: '4px' }} />
//                 <h3 style={{ fontSize: '18px', margin: '0' }}>{result.snippet.title}</h3>
//               </a>
//               <p style={{ margin: '5px 0' }}>{result.snippet.description}</p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Search;
import axios from 'axios';
import React, { useState } from 'react';

const API_KEY = "AIzaSyDPeru1xPfpg0432_rCHm0qjSdVwMFDsfw"; // Your YouTube API key

function Search() {
  const [results, setResults] = useState([]);

  const searchYouTube = async (e) => {
    e.preventDefault();
    const query = document.getElementById("searchInput").value.trim();
    const minFollowers = document.getElementById("minFollowers").value;
    const maxFollowers = document.getElementById("maxFollowers").value;
    const minVideos = document.getElementById("minVideos").value;
    const maxVideos = document.getElementById("maxVideos").value;

    if (!query) {
      alert("Please enter a search query");
      return;
    }

    const maxResults = 10;
    const order = document.getElementById("order") ? document.getElementById("order").value : "relevance";
    const type = order === "viewCount" ? "video" : "channel";

    try {
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/search?part=snippet&q=${query}&type=${type}&order=${order}&maxResults=${maxResults}&key=${API_KEY}`
      );
      const items = response.data.items;

      if (type === "channel") {
        // Filter channels based on followers count and published video count
        const filteredChannels = await Promise.all(
          items.map(async (item) => {
            const channelId = item.id.channelId;
            const channelDetails = await axios.get(
              `https://www.googleapis.com/youtube/v3/channels?part=statistics&id=${channelId}&key=${API_KEY}`
            );
            const statistics = channelDetails.data.items[0].statistics;
            const followersCount = parseInt(statistics.subscriberCount);
            const videoCount = parseInt(statistics.videoCount);

            if ((minFollowers === "" || followersCount >= minFollowers) &&
                (maxFollowers === "" || followersCount <= maxFollowers) &&
                (minVideos === "" || videoCount >= minVideos) &&
                (maxVideos === "" || videoCount <= maxVideos)) {
              return item;
            } else {
              return null;
            }
          })
        );

        setResults(filteredChannels.filter(item => item !== null));
      } else {
        // For video search, no additional filtering required
        setResults(items);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Error fetching data from YouTube. Please try again later.");
    }
  };

  return (
    <div className="content">
      <div className="container mt-4">
        <h1>Search Results</h1>
        <div className="form-group">
          <form className="form-inline ml-auto" onSubmit={searchYouTube}>
            <input className="form-control mr-sm-2" type="search" id="searchInput" placeholder="Search" aria-label="Search" />
            <button className="btn btn-outline-light my-2 my-sm-0" style={{ backgroundColor: '#b6ddf7' }} type="submit">Search</button>
          </form>
          <label htmlFor="order" style={{ margin: '10px 10px' }}>Order By:</label>
          <select className="form-control" id="order">
            <option value="relevance">Channel</option>
            <option value="viewCount">Video</option>
          </select>
          <div className="filter-group mt-3">
            <label htmlFor="minFollowers" style={{ margin: '10px 10px' }}>Min Followers:</label>
            <input className="form-control" type="number" id="minFollowers" placeholder="Min Followers" />

            <label htmlFor="maxFollowers" style={{ margin: '10px 10px' }}>Max Followers:</label>
            <input className="form-control" type="number" id="maxFollowers" placeholder="Max Followers" />

            <label htmlFor="minVideos" style={{ margin: '10px 10px' }}>Min Videos:</label>
            <input className="form-control" type="number" id="minVideos" placeholder="Min Videos" />

            <label htmlFor="maxVideos" style={{ margin: '10px 10px' }}>Max Videos:</label>
            <input className="form-control" type="number" id="maxVideos" placeholder="Max Videos" />
          </div>
        </div>
        <div id="results" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', maxWidth: '100%', boxSizing: 'border-box' }}>
          {results.map(result => (
            <div key={result.id.videoId || result.id.channelId} className="result-item" style={{ paddingTop: '40px', textAlign: 'center', border: '0.5px solid rgb(187, 183, 183)', flex: '1 1 calc(25% - 10px)', maxWidth: 'calc(25% - 10px)', margin: '5px', boxSizing: 'border-box' }}>
              <a href={`/profile?channelId=${result.id.channelId || result.snippet.channelId}`}>
                <img src={result.snippet.thumbnails.medium.url} alt={result.snippet.title} style={{ maxWidth: '100px', marginRight: '10px', borderRadius: '4px' }} />
                <h3 style={{ fontSize: '18px', margin: '0' }}>{result.snippet.title}</h3>
              </a>
              <p style={{ margin: '5px 0' }}>{result.snippet.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Search;
