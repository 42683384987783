import React from 'react';
import { useNavigate } from 'react-router-dom';

function Campaign() {
  const navigate = useNavigate();

  return (
    <div className="content">
      <div className="container mt-4">
        <h1>Campaign Management</h1>
        <button className="btn btn-primary" onClick={() => navigate('/create-campaign')}>Create Campaign</button>
        <button className="btn btn-secondary" onClick={() => navigate('/view-campaigns')}>View Campaigns</button>
      </div>
    </div>
  );
}

export default Campaign;
