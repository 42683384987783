import axios from 'axios';
import React, { useEffect, useState } from 'react';

const API_KEY = "AIzaSyDPeru1xPfpg0432_rCHm0qjSdVwMFDsfw"; // Your YouTube API key

function Profile() {
  const [channelDetails, setChannelDetails] = useState(null);
  const [channelVideos, setChannelVideos] = useState([]);
  const [channelAnalytics, setChannelAnalytics] = useState(null);
  const [genderDemography, setGenderDemography] = useState({ male: 60, female: 40 }); // Mock data

  const getChannelDetails = async (channelId) => {
    try {
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/channels?part=snippet,statistics&id=${channelId}&key=${API_KEY}`
      );
      setChannelDetails(response.data.items[0]);
    } catch (error) {
      console.error("Error fetching channel details:", error);
      alert("Error fetching channel details. Please try again later.");
    }
  };

  const getChannelVideos = async (channelId) => {
    try {
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${channelId}&maxResults=10&order=date&type=video&key=${API_KEY}`
      );
      setChannelVideos(response.data.items);
    } catch (error) {
      console.error("Error fetching channel videos:", error);
      alert("Error fetching channel videos. Please try again later.");
    }
  };

  const getChannelAnalytics = async (channelId) => {
    try {
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/channels?part=statistics&id=${channelId}&key=${API_KEY}`
      );
      setChannelAnalytics(response.data.items[0].statistics);
    } catch (error) {
      console.error("Error fetching channel analytics:", error);
      alert("Error fetching channel analytics. Please try again later.");
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const channelId = urlParams.get('channelId');
    if (channelId) {
      getChannelDetails(channelId);
      getChannelVideos(channelId);
      getChannelAnalytics(channelId);
    }
  }, []);

  return (
    <div className="content">
      <div className="container mt-4">
        <h1>Influencer Profile</h1>
        {channelDetails && (
          <div id="profile">
            <h2>{channelDetails.snippet.title}</h2>
            <p>{channelDetails.snippet.description}</p>
            <p>Subscribers: {channelDetails.statistics.subscriberCount}</p>
            <p>Views: {channelDetails.statistics.viewCount}</p>
          </div>
        )}
        <div id="videos">
          <h2>Latest Videos</h2>
          {channelVideos.map(video => (
            <div key={video.id.videoId} className="video-item">
              <a href={`https://www.youtube.com/watch?v=${video.id.videoId}`} target="_blank" rel="noopener noreferrer">
                <img src={video.snippet.thumbnails.medium.url} alt={video.snippet.title} />
                <h3>{video.snippet.title}</h3>
              </a>
            </div>
          ))}
        </div>
        {channelAnalytics && (
          <div id="analytics">
            <h2>Channel Analytics</h2>
            <p>Views: {channelAnalytics.viewCount}</p>
            <p>Subscribers: {channelAnalytics.subscriberCount}</p>
            <p>Video Count: {channelAnalytics.videoCount}</p>
          </div>
        )}
        <div id="addInfluencerSection" className="mt-4">
          <button className="btn btn-success" onClick={addInfluencerToCampaign}>Add Influencer to Campaign</button>
          <div id="campaignList" className="mt-2"></div>
        </div>
      </div>
    </div>
  );
}

const addInfluencerToCampaign = () => {
  // Mock function, replace with actual implementation if needed
  alert("Influencer added to campaign!");
};

export default Profile;
